import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/auth/authSlice";


function Logout({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout()).then((response) => {

    })
    navigate("/login", { replace: true });
  };
  return <div onClick={onLogout}>{children}</div>;
}

export default Logout;
