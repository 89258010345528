import React from "react";

function ApplicationsAdministrativeData({ single }) {
  return (
    <div className="row no-gutters">

      <div className="col-6">
        {single.application.courier_fullname !== undefined &&
        <div className="info-item">
          <span>ФИО доставщика</span>
          <span>{single.application.courier_fullname}</span>
        </div>
        }
        {single.application.courier_phone !== undefined &&
        <div className="info-item">
          <span>Телефон доставщика</span>
          <span>
              { single.application.courier_phone}
            </span>
        </div>
        }
      </div>
      <div className="col-6">
        {single.application.courier_fullname !== undefined &&
        <div className="info-item">
          <span>ФИО собственника</span>
        </div>
        }
        {single.application.courier_fullname !== undefined &&
        <div className="info-item">
          <span>Телефон собственника</span>

        </div>
        }
      </div>
    </div>
  );
}

export default ApplicationsAdministrativeData;
