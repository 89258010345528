import React from "react";

function ApplicationsComment({
  comment,
  quickEdit,
  setDataQuickEdit,
  dataQuickEdit,
}) {
  function sanitizeHTML(html) {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, "text/html");
    return parsedHtml.body.innerHTML;
  }

  const sanitizedComment = sanitizeHTML(comment);

  return (
    <div className="field-style application__comment">
      {quickEdit ? (
        <textarea
          defaultValue={comment}
          placeholder="Комментарии"
          onChange={(e) =>
            setDataQuickEdit({
              ...dataQuickEdit,
              car_additional: e.target.value,
            })
          }
        ></textarea>
      ) : (
          comment !== undefined &&
              <div dangerouslySetInnerHTML={{ __html: sanitizedComment }} />
      )}
    </div>
  )
}

export default ApplicationsComment;
