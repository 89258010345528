import {useDispatch} from "react-redux";
import {notificationData, notificationReade} from "../../../../../store/notofocations/headerNotificationSlice";

function HeaderNotificationMessageItem(
    {
        item,
        openModal,
        setActiveTabKey,
        setNotifications
    }) {
    const dispatch = useDispatch()



    const handleClick = (notification_id,applicationId, type, orderID, e) => {
        e.preventDefault()
        const id = {
            notificationId:notification_id
        }
        dispatch(notificationReade(id)).then((resp)=>{
            if (resp.payload === 200){
                dispatch(notificationData()).then((resp) => {
                    setNotifications(resp.payload?.notifications)
                })
            }
        })
        switch (type){
            case 'applicationChat' :
                setActiveTabKey(
                    {
                        tabName : 'chat',
                        serviceId :orderID ,
                        applicationId :applicationId
                    })
                openModal(applicationId,type='chat', orderID)
                break
        }
    }

     return (
         item && item.type === 'applicationChat' ?
            <li
                key={item.id}
                className={`${item.type} underline-none notif-item` }
                onClick={(e)=>{handleClick(item.notification_id, item.application_id, item.type, item.order_id, e)}}>
                <a href={'#'} className="all-notif">
                    <div className={'notification__top'} >
                        <div className="notification__top-left-info">
                            {item.sender_email &&
                                <span> {item.sender_email} </span>
                            }
                            {item.sender_role &&
                                <span> ({item.sender_role}) </span>
                            }
                        </div>
                        <div className="notification__top-right-info">
                            {item.date &&
                                <span>{item.date}</span>
                            }
                        </div>
                    </div>
                    {item.short}
                </a>
            </li> : ''

    )
}

export default HeaderNotificationMessageItem;