import React, {useEffect, useState} from 'react';
import MyModal from "../modal/modal";
function ModalParkingServices({isModalOpen,closeModal,parkingServices,currentServicesName}) {
    return (
        <MyModal isOpen={isModalOpen} onClose={closeModal} ariaHideApp={false}>
            <div style={{display:'block', width: '400px', background:'none', transform:'translate(100%, 0)'}}  className="modal fade pricemodal modal-custom show" id="priceModal" tabIndex="-1" role="dialog"
                 aria-labelledby="priceModalt" aria-hidden="true">
                    <div className="modal-content" style={{display:'flex' }}>
                        <div className="modal-header">
                            <h5 className="modal-title">Прайс-лист стоянки  {currentServicesName}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeModal}
                            >
                                <svg width="23" height="22" viewBox="0 0 23 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#176;">
                                        <path id="Icon"
                                              d="M5.07219 4.85247C5.44645 4.49449 6.05323 4.49449 6.42748 4.85247L11.4998 9.70429L16.5722 4.85247C16.9464 4.49449 17.5532 4.49449 17.9275 4.85247C18.3017 5.21045 18.3017 5.79085 17.9275 6.14883L12.8551 11.0007L17.9275 15.8525C18.3017 16.2105 18.3017 16.7909 17.9275 17.1488C17.5532 17.5068 16.9464 17.5068 16.5722 17.1488L11.4998 12.297L6.42748 17.1488C6.05323 17.5068 5.44645 17.5068 5.07219 17.1488C4.69794 16.7909 4.69794 16.2105 5.07219 15.8525L10.1445 11.0007L5.07219 6.14883C4.69794 5.79085 4.69794 5.21045 5.07219 4.85247Z"
                                              fill="#D0D0D0"/>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body" style={{overflowY:'auto', maxHeight:'500px'}}>
                            {parkingServices?.map((item, index) => {
                                // Check if there are any active services
                                const hasActiveServices = item.services?.some(service => service.is_active === 1);

                                return (
                                    <table key={index} className="price-list-table price-table">
                                        {hasActiveServices && (
                                            <thead className={'service-thead'}>
                                                <tr key={'price-list-head-tr'}>
                                                    <th key={'price-list-head-th1'} className="price-list-head">
                                                        <b>{item.category}</b>
                                                    </th>
                                                    <th key={'price-list-head-th2'}></th>
                                                </tr>
                                            </thead>
                                        )}
                                        <tbody className={'service-tbody'}>
                                        {item.services?.map(service => (
                                            service.is_active === 1 && (
                                                <tr key={service.parking_service_id} className=" position-relative">
                                                    <td>{service.parking_service_name}</td>
                                                    <td className="text-right position-relative">
                                                        {service.cost ? `${service.cost}` : 'не указано'}

                                                    </td>
                                                    {service.cost === '0' ?
                                                        <>
                                                        <span className={'tip-service'}>?</span>
                                                        <span>{service.comment}</span>
                                                        </>
                                                        : ''
                                                    }

                                                </tr>
                                            )
                                        ))}
                                        </tbody>
                                    </table>
                                );
                            })}
                        </div>
                    </div>
            </div>

        </MyModal>
    );
}
export default ModalParkingServices;