import React from "react";

function ApplicationModalHeader({ single, setKey }) {
  return (
    <div className="car-row__info d-flex">
      <div className="car-row__col-6">
        <div>
          <h3 className="car-title">
            {single.application?.car_title}
            <span className="car-show-modal show-modal-chat"
            style={{backgroundColor:'#536e9b'}}
            onClick={()=>setKey('chat')}
            >
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 1.5C0.5 0.671573 1.17157 0 2 0H14C14.8284 0 15.5 0.671572 15.5 1.5V9.75C15.5 10.5784 14.8284 11.25 14 11.25H10.5607L8.53033 13.2803C8.23744 13.5732 7.76256 13.5732 7.46967 13.2803L5.43934 11.25H2C1.17157 11.25 0.5 10.5784 0.5 9.75V1.5ZM14 1.5H2V9.75H5.75C5.94891 9.75 6.13968 9.82902 6.28033 9.96967L8 11.6893L9.71967 9.96967C9.86032 9.82902 10.0511 9.75 10.25 9.75H14V1.5Z"
                  fill="#536E9B"
                />
                <path
                  d="M9.125 5.625C9.125 6.24632 8.62132 6.75 8 6.75C7.37868 6.75 6.875 6.24632 6.875 5.625C6.875 5.00368 7.37868 4.5 8 4.5C8.62132 4.5 9.125 5.00368 9.125 5.625Z"
                  fill="#536E9B"
                />
                <path
                  d="M12.125 5.625C12.125 6.24632 11.6213 6.75 11 6.75C10.3787 6.75 9.875 6.24632 9.875 5.625C9.875 5.00368 10.3787 4.5 11 4.5C11.6213 4.5 12.125 5.00368 12.125 5.625Z"
                  fill="#536E9B"
                />
                <path
                  d="M6.125 5.625C6.125 6.24632 5.62132 6.75 5 6.75C4.37868 6.75 3.875 6.24632 3.875 5.625C3.875 5.00368 4.37868 4.5 5 4.5C5.62132 4.5 6.125 5.00368 6.125 5.625Z"
                  fill="#536E9B"
                />
              </svg>
            </span>
          </h3>
          <span className="car__subtitle">
            {single.application?.parking?.title}
          </span>
          <span className="car__subtitle">ID {single?.application?.id}</span>
        </div>
        <div className="d-flex">
          <div className="car-row__vin">
            <span>VIN</span>
            {single?.application?.vin}
          </div>
          <div>
            <span>Гос. номер</span>
            {single?.application?.license_plate}
          </div>
        </div>
      </div>
      <div className="car-row__col-6 text-right">
        <div className="fs-0">

          {/* <label className="mr-0 mb-0 border-0">
            <select className="status-select theme-back" name="statusid">
              <option value={single.application.status.id} selected>
                {single.application.status.name}
              </option>
            </select>
          </label> */}

          <span className="car-row__status"></span>
          <span className="car-row__status">
            {single?.application?.returned ? "Повтор" : ""}
          </span>

          <span></span>
          <span>{single?.application?.partner?.name}</span>
          <span>{single?.application?.external_id}</span>
        </div>
        <div className="d-flex justify-content-end">
          <div className="date-delivery ml-auto">
            <span>Дата постановки</span>
            {single?.application?.formated_arrived_at}
          </div>
          <div>
            <span>Дата выдачи</span>
            {single?.application?.issuance_date
              ? single?.application?.issuance_date
              : "Нет"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationModalHeader;
