import React from "react";

function ApplicationAboutCar({ single }) {
  return (
    <div className="row no-gutters">
      <div className="col-4">
          {single.application?.pts || single.application?.pts_type || single.application.pts_provided ?
              <div className="info-item">
                  <span>ПТС</span>

                      <span>
                  {single.application?.pts}
                          {single.application?.pts_type}
                          <br/>
                          {single.application.pts_provided && "(Принят на хранение)"}
              </span>
              </div> : ''
          }
          {single.application?.owner_number &&
          <div className="info-item">
              <span>Кол-во владельцев</span>
              {single.application?.owner_number}
          </div>
          }
      </div>
      <div className="col-4">
          {single.application?.sts || single.application.sts_provided ?
              <div className="info-item">
                  <span>СТС</span>
                      <span>
                            {single.application?.sts}
                          <br/> {single.application.sts_provided && "(Принят на хранение)"}
                    </span>
              </div> : ''
          }
          {single.application?.car_key_quantity &&
          <div className="info-item">
              <span>Кол-во ключей</span>
              {single.application?.car_key_quantity}
          </div>
          }
      </div>
      <div className="col-4">
          {single.application?.milage &&
          <div className="info-item">
              <span>Пробег, км</span>
              <span>{single.application?.milage}км</span>
          </div>
          }
      </div>
    </div>
  );
}

export default ApplicationAboutCar;
